import React, { useContext, useEffect, useState } from 'react'
import { theme } from '../../../utils/theme'
import FilterContext from '../../../contexts/filter/FilterContext'
import Button from '../../reusable_components/buttons/Button'
import Slider from 'react-slick'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import { Link } from 'react-router-dom'
import { TagsWrapperDesktop, TagsWrapperMobile } from '../../sub_categories/show/SkillTags'
import { P4 } from '../../reusable_components/Typography'
import useRoutes from '../../../hooks/useRoutes'

const SubCategoryLinks = ({ type, t, categories }) => {
	const { filterState, dispatch } = useContext(FilterContext)
	let skillTagsData
	if (type === 'SUB_CATEGORY') {
		skillTagsData = filterState.subCategories
	} else if (type === 'CATEGORY') {
		skillTagsData = Object.values(filterState.categories)
	} else if (type === 'CATEGORY_NO_FILTER') {
		skillTagsData = categories
	}
	if (skillTagsData.length === 0) return null
	let numberOfShownTags = 3
	if (skillTagsData.length - 1 === numberOfShownTags) numberOfShownTags++ // Since we add a button if there are more, check if there is only one more, then we might as well show it instead of the button
	let showSeeMoreButton = skillTagsData.length > numberOfShownTags
	const skillTags = skillTagsData
		.sort((a, b) => b.listedCoursesCount - a.listedCoursesCount)
		.slice(0, numberOfShownTags)
		.map(({ name, path, listedCoursesCount }, tagIndex) => {
			const button = (
				<Button buttonType="ROUND_THIN" color="WHITE" disable={!listedCoursesCount} key={tagIndex}>
					{name}&nbsp;<P4 textColor="lightTextColor">({listedCoursesCount})</P4>
				</Button>
			)
			return listedCoursesCount ? (
				<Link to={path} key={tagIndex}>
					{button}
				</Link>
			) : (
				button
			)
		})
	const seeMoreButton = showSeeMoreButton && (
		<div>
			{type === 'CATEGORY_NO_FILTER' ? (
				<Link to={useRoutes().course.index.path}>
					<Button color="OUTLINED_BLACK" buttonType="ROUND_THIN">
						{t('common:buttons.seeMore')}
					</Button>
				</Link>
			) : (
				<Button
					color="OUTLINED_BLACK"
					buttonType="ROUND_THIN"
					onClick={() =>
						dispatch({
							type: 'OPEN_FILTER_OPTION',
							data: type,
						})
					}
				>
					{t('common:buttons.seeMore')}
				</Button>
			)}
		</div>
	)
	return (
		<>
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
				<SubCategoryLinksMobileLoader>
					{skillTags}
					{seeMoreButton}
				</SubCategoryLinksMobileLoader>
			</Breakpoint>
			<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
				<TagsWrapperDesktop>
					{skillTags}
					{seeMoreButton}
				</TagsWrapperDesktop>
			</Breakpoint>
		</>
	)
}

export const SubCategoryLinksMobileLoader = ({ children }) => {
	const [loaded, setLoaded] = useState(false)
	const settings = {
		dots: false,
		infinite: false,
		arrows: false,
		swipeToSlide: true,
		variableWidth: true,
	}
	useEffect(() => {
		// The slider loads weird on mobile (especially when many tags exist), so let it fully render first and then show it
		setLoaded(true)
	}, [])
	return (
		<TagsWrapperMobile loaded={loaded}>
			<Slider {...settings}>{children}</Slider>
		</TagsWrapperMobile>
	)
}

export default SubCategoryLinks
