import React from 'react'
import {
	CenteredPageSection,
	CenteredSection,
	PageSidePaddingWrapper,
} from '../../reusable_components/layout/PageComponents'
import HelmetHead from '../../layout/HelmetHead'
import { ContentSliderImage } from '../../courses/show/ContentSlider'
import { HeaderWrapper } from '../../courses/show/HeaderSection'
import { getMarketplace } from '../../../utils/applicationHelper'
import { theme } from '../../../utils/theme'
import ResponsiveImage from '../../reusable_components/responsive/ResponsiveImage'
import styled from 'styled-components'
import {
	BelowFixedHeader,
	FixedHeader,
	FixedHeaderPlaceHolder,
	MarketplaceFrontendComponent,
} from '../show/MarketplacePage'
import ReusableSlider from '../../reusable_components/other/ReusableSlider'
import { P1 } from '../../reusable_components/Typography'

const MarketplacePage = ({ t, footerLinks }) => {
	const { title, description, image, frontendComponents } = getMarketplace()
	return (
		<>
			<HelmetHead title={title} description={description} image={image} topLevelHelmet />
			<FixedHeader id="FIXED_DIV">
				<HeaderWrapper>
					<ContentSliderImage image={image} alt={title} />
					<div>
						<div>
							<PageSidePaddingWrapper>
								<CenteredSection>
									<h1>{title}</h1>
									<p>{description}</p>
								</CenteredSection>
							</PageSidePaddingWrapper>
						</div>
					</div>
				</HeaderWrapper>
			</FixedHeader>
			<FixedHeaderPlaceHolder />
			<BelowFixedHeader style={{ paddingTop: theme.paddings.XLarge }}>
				<CenteredPageSection>
					<P1>{t('copy:marketplacesPage.categories')}</P1>
				</CenteredPageSection>
				<MarketplaceSlider marketplaces={footerLinks.marketplaces} />
				<PageSidePaddingWrapper>
					{frontendComponents.map((frontendComponent, key) => (
						<MarketplaceFrontendComponent key={key} reversedOnDesktop={key % 2 !== 0} {...frontendComponent} t={t} />
					))}
				</PageSidePaddingWrapper>
			</BelowFixedHeader>
		</>
	)
}

const MarketplaceWrapper = styled.div`
	position: relative;
	font-size: ${theme.fontSizes.p3};
	line-height: ${theme.lineHeights.p3};
	> div:nth-child(2) {
		position: absolute;
		top: 0;
		left: 0;
		color: white;
		z-index: 2;
		padding: 7px 0 0 10px;
	}
	> div {
		border-radius: 3px;
	}
	> div:last-child {
		position: absolute;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) -0.62%, rgba(255, 255, 255, 0) 50%);
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
`

export const MarketplaceSlider = ({ marketplaces }) => (
	<ReusableSlider desktopSlidesToShow={5}>
		{marketplaces.map((marketplace, key) => (
			<a href={marketplace.url} key={key}>
				<MarketplaceWrapper>
					<ResponsiveImage imagePath={marketplace.image} type="BIG_RECTANGLE" alt={marketplace.title} />
					<div>{marketplace.title}</div>
					<div />
				</MarketplaceWrapper>
			</a>
		))}
	</ReusableSlider>
)

export default MarketplacePage
