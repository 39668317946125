import React, { useState } from 'react'
import styled from 'styled-components'
import {
	CenteredSection,
	DynamicPageSection,
	PageSectionRow,
	PageSidePaddingWrapper,
	SmallPageSection,
} from '../reusable_components/layout/PageComponents'
import Slider from 'react-slick'
import { P2 } from '../reusable_components/Typography'
import { theme } from '../../utils/theme'
import Breakpoint from '../reusable_components/responsive/Breakpoint'
import CrocodileRight from '../reusable_components/svg_icons/CrocodileRight'
import ResponsiveImage from '../reusable_components/responsive/ResponsiveImage'

const ImageWrapper = styled.div`
	${theme.breakpoints.tablet.upToAndIncluding} {
		width: 100%;
		padding: 0 ${theme.paddings.small};
	}
`
const ImageSlider = ({ t, i18n }) => {
	const imageColumns = ['1', '2', '3']
	const [allowLeftSlide, setAllowLeftSlide] = useState(false)
	const [allowRightSlide, setAllowRightSlide] = useState(true)
	const beforeChange = (currentIndex, nextIndex) => {
		if (nextIndex === 0) {
			// The slider is at the start
			setAllowLeftSlide(false)
		} else if (!allowLeftSlide) {
			// The slider was at the start, but is not longer at the start
			setAllowLeftSlide(true)
		}
		if (nextIndex + 1 >= imageColumns.length) {
			// The slider is at the end
			setAllowRightSlide(false)
		} else if (!allowRightSlide) {
			// The slider was at the end, but is not longer at the end
			setAllowRightSlide(true)
		}
	}
	const settings = {
		dots: false,
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		adaptiveHeight: true,
		nextArrow: <NextArrow disabled={!allowRightSlide} />,
		prevArrow: <PreviousArrow disabled={!allowLeftSlide} />,
		beforeChange: beforeChange,
	}
	return (
		<DynamicPageSection margins={{ top: theme.margins.XLargeInt, bottom: theme.margins.smallInt }}>
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
				<SmallPageSection>
					<Slider {...settings}>
						{imageColumns.map(column => (
							<ImageColumn column={column} key={column} i18n={i18n} t={t} />
						))}
					</Slider>
				</SmallPageSection>
			</Breakpoint>
			<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
				<PageSidePaddingWrapper>
					<PageSectionRow>
						{imageColumns.map(column => (
							<ImageColumn column={column} key={column} i18n={i18n} t={t} />
						))}
					</PageSectionRow>
				</PageSidePaddingWrapper>
			</Breakpoint>
		</DynamicPageSection>
	)
}

export default ImageSlider

const ImageColumn = ({ column, i18n, t }) => {
	const imagePath = `column_image_${column}`
	return (
		<ImageWrapper>
			<div>
				<ResponsiveImage
					imagePath={`static_assets/start_page/${i18n.language === 'sv' ? imagePath : `${imagePath}_eng`}`}
					type="VERTICAL_RECTANGLE"
				/>
			</div>
			<br />
			<CenteredSection>
				<P2>{t('copy:startPage.imageText' + column)}</P2>
			</CenteredSection>
		</ImageWrapper>
	)
}
const SliderButton = styled.div`
	height: 100%;
	color: ${theme.colors.lightTextColor};
	opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
	transition: opacity 0.3s;
	justify-content: center;
	align-items: center;
	right: ${({ right }) => (right ? '-5px' : 'unset')};
	left: ${({ right }) => (right ? 'unset' : '-5px')};
	bottom: 0;
	position: absolute;
	display: flex;
	cursor: pointer;
	z-index: 1;
	& svg {
		transform: rotate(${({ right }) => (right ? 0 : '180')}deg);
		width: 30px;
		height: 30px;
	}
`
function NextArrow(props) {
	const { onClick, disabled } = props
	return (
		<SliderButton right onClick={onClick} disabled={disabled}>
			<CrocodileRight />
		</SliderButton>
	)
}
function PreviousArrow(props) {
	const { onClick, disabled } = props
	return (
		<SliderButton onClick={onClick} disabled={disabled}>
			<CrocodileRight />
		</SliderButton>
	)
}
