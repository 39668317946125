import React, { useEffect, useState } from 'react'
import { theme } from '../../utils/theme'

import Button from '../reusable_components/buttons/Button'
import {
	CenteredPageSection,
	CenteredSection,
	DynamicPageSection,
	DynamicPageSectionRow,
	GrayBox,
	LazyLoad,
	PageSidePaddingWrapper,
	XSmallPageSection,
} from '../reusable_components/layout/PageComponents'
import { P1, P2, P4, TextSpan } from '../reusable_components/Typography'
import Breakpoint from '../reusable_components/responsive/Breakpoint'
import GetStartedButton from '../static_pages/ejbla_pro_page/GetStartedButton'
import ResponsiveImage from '../reusable_components/responsive/ResponsiveImage'
import BookDemoButton from '../static_pages/ejbla_pro_page/BookDemoButton'
import { Trans } from 'react-i18next-new'
import ReusableSlider from '../reusable_components/other/ReusableSlider'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getCloudinaryImageUrl, getCloudinaryVideoUrl } from '../../utils/imageHelper'
import ImageSlider from './ImageSlider'
import { MarketplaceSlider } from '../marketplaces/index/MarketplacesPage'
import { ContentSliderImage } from '../courses/show/ContentSlider'
import { HeaderWrapper } from '../courses/show/HeaderSection'

const VideoWrapper = styled.div`
	overflow: hidden;
	position: relative;
	height: 0;
	width: 100%;
	${theme.breakpoints.tablet.upToAndIncluding} {
		padding-bottom: 113.8%;
		background-image: url('${getCloudinaryImageUrl(
			'static_assets/start_page/video_poster_mobile',
			250,
			'ORIGINAL_RATIO',
			',e_blur',
		)}');
	}
	${theme.breakpoints.tablet.overAndExcluding} {
		padding-bottom: 41.7%;
		background-image: url('${getCloudinaryImageUrl(
			'static_assets/start_page/video_poster_desktop',
			400,
			'ORIGINAL_RATIO',
			',e_blur',
		)}');
	}
	background-size: cover;
	> video {
		opacity: ${({ loaded }) => (loaded ? 1 : 0)};
		transition: opacity 0.4s;
		position: absolute;
		width: 100%;
	}
	> div {
		background: #0000003d;
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		> div {
			margin-top: ${theme.margins.large}; // Force some space between the text and the page header
			> div:first-child {
				color: white;
				${theme.breakpoints.tablet.overAndExcluding} {
					h1 {
						font-size: 50px;
						line-height: 50px;
					}
					p {
						max-width: 550px; //Force the text to be more centered with a max width, so e.g the first line of text isn't super wide and the second line only a couple of words wide. Adjust the max width to suite the current copy. Same should be done for the h1, but that can also be adjusted with font-size
					}
				}
			}
		}
	}
`

const StartPage = props => {
	const { t, i18n, user, routes, footerLinks } = props
	const numberColumns = ['1', '2', '3', '4']
	const organizerReviewColumns = ['1', '2', '3', '4']
	const [videoLoaded, setVideoLoaded] = useState(false)
	const SmartToolsGoldText = () => (
		<Trans i18nKey={'copy:startPage.pageSubTitle'}>
			Smarta verktyg för att <TextSpan textColor="gold">administrera</TextSpan> och <TextSpan textColor="gold">marknadsföra</TextSpan> kurs och event. Skapa en proffsig bokningssida och ta emot bokningar på några minuter. Kom igång utan fasta kostnader eller bindningstid.
		</Trans>
	) // prettier-ignore
	const PriceGoldText = () => (
		<Trans i18nKey={'copy:startPage.goldenText1'}>
	Kom igång <TextSpan textColor="gold">gratis</TextSpan>, <TextSpan textColor="gold">skala upp enkelt</TextSpan>. Starta utan förbindelser eller fasta kostnader.
		</Trans>
	) // prettier-ignore
	const PassionGoldText = () => (
		<Trans i18nKey={'copy:startPage.goldenText3'}>
			Gör som <TextSpan textColor="gold">hundratals</TextSpan> entreprenörer, kreatörer och ämnesexperter; använd Ejbla för att tjäna pengar på din passion.
		</Trans>
	) // prettier-ignore
	const FounderGoldText = () => (
		<Trans i18nKey={'copy:startPage.goldenText4'}>
			Vi vill göra det möjligt för fler att leva på sin <TextSpan textColor="gold">passion</TextSpan> genom att dela sin <TextSpan textColor="gold">kunskap</TextSpan>
		</Trans>
	) // prettier-ignore
	const ScaleUpGoldText = () => (
		<Trans i18nKey={'copy:startPage.goldenText2'}>
			Med Ejbla är det enkelt att
			<TextSpan textColor="gold">starta, bygga</TextSpan> och
			<TextSpan textColor="gold">skala upp</TextSpan> din verksamhet.
		</Trans>
	)
	const getVideoUrl = type => getCloudinaryVideoUrl(`videos/ejbla_${type}`)
	useEffect(() => {
		const video = document.getElementById('START_PAGE_VIDEO')
		video.addEventListener(
			'loadeddata',
			() => {
				setVideoLoaded(true)
			},
			false,
		)
		video.load() // Event listener loadeddata isn't triggered unless we do load(), even though we have autoPlay
	}, [])
	return (
		<>
			<VideoWrapper loaded={videoLoaded}>
				<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
					<video id="START_PAGE_VIDEO" preload="auto" muted loop autoPlay playsInline width="100%" height="100%">
						<source src={getVideoUrl('mobile')} />
					</video>
				</Breakpoint>
				<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
					<video id="START_PAGE_VIDEO" preload="auto" muted loop autoPlay playsInline width="100%" height="100%">
						<source src={getVideoUrl('desktop')} />
					</video>
				</Breakpoint>
				<div>
					<PageSidePaddingWrapper>
						<CenteredSection>
							<h1>{t('copy:startPage.pageTitle')}</h1>
						</CenteredSection>
						<DynamicPageSectionRow
							margins={{ outer: theme.margins.smallInt, inner: theme.margins.XSmallInt }}
							centered
							preserveWidth
							alignItems="center"
						>
							<div>
								<GetStartedButton primary>{t('common:buttons.hostEvents')}</GetStartedButton>
							</div>
							<a href={footerLinks.marketplacesIndex.url}>
								<Button color="OUTLINED_BLACK">{t('common:buttons.findAndBook')}</Button>
							</a>
						</DynamicPageSectionRow>
					</PageSidePaddingWrapper>
				</div>
			</VideoWrapper>
			<DynamicPageSection margins={{ all: theme.margins.XLargeInt }}>
				<PageSidePaddingWrapper>
					<CenteredSection>
						<P2>
							<SmartToolsGoldText />
						</P2>
						{user.isHost || <XSmallPageSection />}
						<GetStartedButton>{t('common:buttons.getStartedFree')}</GetStartedButton>
					</CenteredSection>
				</PageSidePaddingWrapper>
			</DynamicPageSection>
			<LazyLoad>
				<ImageSlider t={t} i18n={i18n} />
			</LazyLoad>
			<CenteredSection>
				<BookDemoButton t={t} />
			</CenteredSection>
			<DynamicPageSection margins={{ all: theme.margins.XXLargeInt }}>
				<GrayBox style={{ padding: '160px 0' }}>
					<PageSidePaddingWrapper>
						<CenteredSection>
							<P1>
								<PassionGoldText />
							</P1>
						</CenteredSection>
					</PageSidePaddingWrapper>
				</GrayBox>
			</DynamicPageSection>
			<CenteredPageSection>
				<P1>{t('copy:startPage.secondH2SubTitle')}</P1>
			</CenteredPageSection>
			<LazyLoad>
				<ReusableSlider>
					{organizerReviewColumns.map((column, key) => (
						<OrganizerReviewCard key={key} column={column} t={t} />
					))}
				</ReusableSlider>
			</LazyLoad>
			<PageSidePaddingWrapper>
				<DynamicPageSection margins={{ all: theme.margins.XXLargeInt }}>
					<CenteredSection>
						<P1>
							<PriceGoldText />
						</P1>
						<XSmallPageSection />
						<Link to={routes.static.pricing.path}>
							<Button color="OUTLINED_BLACK">{t('common:buttons.seePrices')}</Button>
						</Link>
					</CenteredSection>
				</DynamicPageSection>
			</PageSidePaddingWrapper>
			<GrayBox style={{ padding: '160px 0' }}>
				<PageSidePaddingWrapper>
					<CenteredSection>
						<P1>{t('copy:startPage.thirdH2SubTitle')}</P1>
						<XSmallPageSection />
					</CenteredSection>
					<DynamicPageSectionRow
						margins={{ all: theme.margins.smallInt, innerHorizontalDesktop: theme.margins.largeInt }}
					>
						{numberColumns.map(column => (
							<NumberColumn column={column} key={column} t={t} />
						))}
					</DynamicPageSectionRow>
					<CenteredSection>
						<XSmallPageSection />
						<a href="https://app.vaam.io/share/SppVIBb" target="_blank">
							<Button color="OUTLINED_BLACK">{t('common:buttons.watchPreview')}</Button>
						</a>
					</CenteredSection>
				</PageSidePaddingWrapper>
			</GrayBox>
			<DynamicPageSection margins={{ all: theme.margins.XXLargeInt }}>
				<PageSidePaddingWrapper>
					<CenteredSection>
						<P1>
							<ScaleUpGoldText />
						</P1>
						<XSmallPageSection />
						<BookDemoButton t={t} />
					</CenteredSection>
				</PageSidePaddingWrapper>
			</DynamicPageSection>
			<LazyLoad>
				<HeaderWrapper>
					<ContentSliderImage image="d2wf0lnza3wudxsxkuap" />
					<div>
						<div>
							<PageSidePaddingWrapper>
								<CenteredSection>
									<h1>{t('copy:startPage.fourthH2SubTitle')}</h1>
									<p>{t('copy:startPage.fourthH2Text')}</p>
								</CenteredSection>
							</PageSidePaddingWrapper>
							<XSmallPageSection />
							<a href={footerLinks.marketplacesIndex.url}>
								<Button color="OUTLINED_BLACK">{t('common:buttons.readmore')}</Button>
							</a>
						</div>
					</div>
				</HeaderWrapper>
			</LazyLoad>
			<LazyLoad>
				<DynamicPageSection margins={{ top: theme.margins.smallInt }}>
					<MarketplaceSlider marketplaces={footerLinks.marketplaces} />
				</DynamicPageSection>
			</LazyLoad>
			<LazyLoad>
				<DynamicPageSection margins={{ top: theme.margins.XXLargeInt, bottom: theme.margins.XXLargeInt }}>
					<HeaderWrapper>
						<ContentSliderImage image={footerLinks.marketplacesIndex.image} />
						<div>
							<div>
								<PageSidePaddingWrapper>
									<CenteredSection>
										<h1>{t('copy:startPage.fifthH2SubTitle')}</h1>
										<p>{t('copy:startPage.fifthH2SubText')}</p>
									</CenteredSection>
								</PageSidePaddingWrapper>
								<XSmallPageSection />
								<a href={footerLinks.marketplacesIndex.url}>
									<Button color="OUTLINED_BLACK">{t('common:buttons.findAndBook')}</Button>
								</a>
							</div>
						</div>
					</HeaderWrapper>
				</DynamicPageSection>
			</LazyLoad>
			<PageSidePaddingWrapper>
				<LazyLoad>
					<DynamicPageSectionRow
						margins={{
							topDesktop: theme.margins.XXLargeInt,
							topMobile: theme.margins.largeInt,
							innerHorizontalDesktop: theme.margins.largeInt,
							innerMobile: theme.margins.smallInt,
							bottom: theme.margins.XXLargeInt,
						}}
					>
						<div>
							<CenteredSection onlyCenterOnMobile>
								<P1>
									<FounderGoldText />
								</P1>
							</CenteredSection>
							{/*<DynamicPageSection margins={{ top: theme.margins.largeInt }}>
								<CenteredSection onlyCenterOnMobile>
									<Link to={routes.static.pricing.path}>
										<Button color="OUTLINED_BLACK">{t('common:buttons.readmore')}</Button>
									</Link>
								</CenteredSection>
							</DynamicPageSection>*/}
						</div>
						<div>
							<ResponsiveImage imagePath={`Matilda_Hannäs_painting`} type="BIG_RECTANGLE" alt="Matilda Hannäs" />
							<p>Matilda Hannäs</p>
							<P4 textColor="lightTextColor">{t('copy:startPage.founder')}</P4>
						</div>
					</DynamicPageSectionRow>
				</LazyLoad>
			</PageSidePaddingWrapper>
			{/*<FaqSection>
				<div>
					<Faq
						question={t('copy:startPage.faqQuestion1')}
						answer={t('copy:startPage.faqAnswer1', { url: routes.static.pricing.path })}
					/>
					<Faq question={t('copy:startPage.faqQuestion2')} answer={t('copy:startPage.faqAnswer2')} />
				</div>
				<div>
					<Faq question={t('copy:startPage.faqQuestion3')} answer={t('copy:startPage.faqAnswer3')} />
					<Faq question={t('copy:startPage.faqQuestion4')} answer={t('copy:startPage.faqAnswer4')} />
				</div>
			</FaqSection>*/}
		</>
	)
}

const NumberColumn = ({ column, t }) => {
	return (
		<div style={{ display: 'flex' }}>
			<P2>{column}</P2>
			<div style={{ marginLeft: theme.margins.small }}>
				<P2>{t(`copy:startPage.numberColumn${column}Title`)}</P2>
				<XSmallPageSection />
				<p>{t(`copy:startPage.numberColumn${column}Text`)}</p>
			</div>
		</div>
	)
}
const OrganizerReviewCard = ({ column, t }) => {
	const imagePath = `organizer_review_image__${column}`
	return (
		<div>
			<a href={t(`copy:startPage.organizerReview${column}Link`)} target="_blank">
				<ResponsiveImage
					imagePath={imagePath}
					alt={imagePath}
					type="BIG_VERTICAL_RECTANGLE"
					// alt={`${fullName} - ${expertType}`}
					sourceWidth={theme.breakpoints.phone.maxInt}
				/>
				<XSmallPageSection>
					<P2>{t(`copy:startPage.organizerReview${column}Name`)}</P2>
				</XSmallPageSection>
				<XSmallPageSection>
					<P4 textColor="lightTextColor">{t(`copy:startPage.organizerReview${column}Type`)}</P4>
				</XSmallPageSection>
				<P4>{t(`copy:startPage.organizerReview${column}Text`)}</P4>
			</a>
		</div>
	)
}

export default StartPage
