import React from 'react'
import {
	CenteredPageSection,
	CenteredSection,
	DynamicPageSection,
	DynamicPageSectionRow,
	LazyLoad,
	PageSidePaddingWrapper,
	XLargePageSectionDivider,
	XSmallPageSection,
} from '../../reusable_components/layout/PageComponents'
import HelmetHead from '../../layout/HelmetHead'
import { ContentSliderImage } from '../../courses/show/ContentSlider'
import { HeaderWrapper } from '../../courses/show/HeaderSection'
import { getMarketplace } from '../../../utils/applicationHelper'
import SubCategoryLinks from '../../categories/show/SubCategoryLinks'
import styled from 'styled-components'
import CourseCardsWrapper from '../../reusable_components/course_cards/CourseCardsWrapper'
import { theme } from '../../../utils/theme'
import Button from '../../reusable_components/buttons/Button'
import { Link } from 'react-router-dom'
import { P1, P4 } from '../../reusable_components/Typography'
import ResponsiveImage from '../../reusable_components/responsive/ResponsiveImage'
import ConditionalLink from '../../reusable_components/other/ConditionalLink'
import { noMarginForFirstAndLastChild } from '../../reusable_components/PageMixins'

export const FixedHeader = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
`

// Needed below a fixed header to make sure the content below it is put on top of the fixed header
export const BelowFixedHeader = styled.div`
	background: white;
	padding-bottom: ${theme.paddings
		.large}; // Padding is needed at the bottom to prevent the fixed header no to be visible between last element in BelowFixedHeader and footer. Last element in BelowFixedHeader shouldn't have bottom margin.
	position: relative;
	z-index: 2;
	${noMarginForFirstAndLastChild}
`

// Needed to push down the content below the fixed header. Has the same height as the fixed header
export const FixedHeaderPlaceHolder = () => (
	<div style={{ opacity: 0, pointerEvents: 'none' }}>
		<ContentSliderImage />
	</div>
)

const MarketplacePage = ({ ssrCourseCardData, t, routes, footerLinks }) => {
	const { id, title, description, image, frontendComponents } = getMarketplace()
	return (
		<>
			<HelmetHead title={title} description={description} image={image} topLevelHelmet />
			<FixedHeader id="FIXED_DIV">
				<HeaderWrapper>
					<ContentSliderImage image={image} alt={title} />
					<div>
						<div>
							<PageSidePaddingWrapper>
								<CenteredSection>
									<h1>{title}</h1>
									<p>{description}</p>
								</CenteredSection>
							</PageSidePaddingWrapper>
							<XSmallPageSection />
							<SubCategoryLinks type="CATEGORY_NO_FILTER" t={t} categories={footerLinks.categories} />
						</div>
					</div>
				</HeaderWrapper>
			</FixedHeader>
			<FixedHeaderPlaceHolder />
			<BelowFixedHeader style={{ paddingTop: theme.paddings.large }}>
				<PageSidePaddingWrapper>
					<CourseCardsWrapper
						type="marketplace"
						id={id}
						optimizeRows
						quantity={4}
						title={t('copy:marketplacePage.courseCardsTitle')}
						ssrCourseCardData={ssrCourseCardData}
					/>
					<CenteredPageSection>
						<Link to={routes.course.index.path}>
							<Button color="OUTLINED_BLACK">{t('common:buttons.seeMore')}</Button>
						</Link>
					</CenteredPageSection>
					{frontendComponents.map((frontendComponent, key) => (
						<MarketplaceFrontendComponent key={key} reversedOnDesktop={key % 2 !== 0} {...frontendComponent} t={t} />
					))}
				</PageSidePaddingWrapper>
			</BelowFixedHeader>
		</>
	)
}

export const MarketplaceFrontendComponent = ({ title, headline, description, link, image, reversedOnDesktop, t }) => (
	<LazyLoad>
		<XLargePageSectionDivider />
		<DynamicPageSectionRow
			reversedOnDesktop={reversedOnDesktop}
			margins={{
				topDesktop: theme.margins.XLargeInt,
				topMobile: theme.margins.largeInt,
				innerHorizontalDesktop: theme.margins.largeInt,
				innerMobile: theme.margins.largeInt,
				bottom: theme.margins.largeInt,
			}}
		>
			<div>
				<CenteredSection onlyCenterOnMobile>
					<P1>{title}</P1>
					{headline && (
						<>
							<XSmallPageSection />
							<P4 textColor="lightTextColor">{headline}</P4>
						</>
					)}
					<DynamicPageSection
						margins={{ topMobile: theme.margins.smallInt, topDesktop: theme.margins.largeInt, bottom: 0 }}
					>
						<p>{description}</p>
					</DynamicPageSection>
				</CenteredSection>
				{link && (
					<DynamicPageSection margins={{ topMobile: theme.margins.smallInt, topDesktop: theme.margins.largeInt }}>
						<CenteredSection onlyCenterOnMobile>
							<ConditionalLink to={link}>
								<Button color="OUTLINED_BLACK">{t('common:buttons.readmore')}</Button>
							</ConditionalLink>
						</CenteredSection>
					</DynamicPageSection>
				)}
			</div>
			<div>
				<ResponsiveImage imagePath={image} type="BIG_RECTANGLE" alt={title} />
			</div>
		</DynamicPageSectionRow>
	</LazyLoad>
)

export default MarketplacePage
